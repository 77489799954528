import React from "react"
import { graphql } from "gatsby"
import Breadcrumb from "../components/breadcrumb"
import Layout from "./default"
import PageTitle from "../components/page-title"
import RoomNavigationButton from "../components/room-navigation-button"
import RoomNavigationFooter from "../components/room-navigation-footer"
import Seo from "../components/seo"
import Stack from "../components/stack"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

const LayoutDauerAusstellung = ({ data: { mdx, ogImage }, pageContext }) => {
  const { title, backdrop, description } = mdx.frontmatter
  const { previous, next } = pageContext

  const basePath = "/dauerausstellung"
  const mdxWrapper = (props) => <Stack space={[24, 24, 32, 48]} {...props} />

  return (
    <Layout backdrop={backdrop || "dauerausstellung"}>
      <Seo
        title={title || null}
        description={description ? `${description.substring(0, 150)}…` : null}
        image={ogImage.frontmatter.cover.childImageSharp.gatsbyImageData}
      />
      <RoomNavigationButton />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Dauerausstellung",
              link: `${basePath}`,
            },
            {
              title: title,
              link: `${basePath}${mdx.fields.slug}`,
            },
          ]}
        />
        <PageTitle>{title}</PageTitle>
        <Stack space={48}>
          <MDXProvider
            components={{
              wrapper: mdxWrapper,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
          <RoomNavigationFooter previous={previous} next={next} />
        </Stack>
      </Stack>
    </Layout>
  )
}

export const pageQuery = graphql`
  query DauerausstellungDetailQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 150)
      fields {
        slug
      }
      frontmatter {
        title
        description
        backdrop
        cover {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
      }
    }
    ogImage: mdx(id: { eq: $id }) {
      frontmatter {
        cover {
          childImageSharp {
            gatsbyImageData(width: 800, height: 800, layout: FIXED)
          }
        }
      }
    }
  }
`

export default LayoutDauerAusstellung
