import React, { useState } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Box from "./box"

import Grid from "./grid"
import Heading from "./heading"
import Icon from "./icon"
import Inline from "./inline"
import RoomNavigationItem from "./room-navigation-item"
import Stack from "./stack"
import VisuallyHidden from "./visually-hidden"

const RoomNavigationFooter = (props) => {
  const { previous, next } = props

  return (
    <Box as="nav">
      <VisuallyHidden>
        <h2>Vorheriger und nächster Raum</h2>
      </VisuallyHidden>
      <Grid as="ol" columns={[1, 2]}>
        <Box height="100%">
          {previous && (
            <Stack space={3}>
              <Link to={`/dauerausstellung${previous.childMdx.fields.slug}`}>
                <Inline alignY="center">
                  <Icon icon="arrow-left" color="primary" />
                  <Heading as="span" level={6}>
                    Vorheriger Raum
                  </Heading>
                </Inline>
              </Link>
              <RoomNavigationItem headingElement="span" room={previous} />
            </Stack>
          )}
        </Box>
        <Box height="100%">
          {next && (
            <Stack space={3}>
              <Link to={`/dauerausstellung${next.childMdx.fields.slug}`}>
                <Inline alignY="center" alignX="right">
                  <Heading as="span" level={6} align="right">
                    Nächster Raum
                  </Heading>
                  <Icon icon="arrow-right" color="primary" />
                </Inline>
              </Link>
              <RoomNavigationItem headingElement="span" room={next} reverse />
            </Stack>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default RoomNavigationFooter
